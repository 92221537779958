.select-checkbox {
    padding: 20px;
  }
  
  .select-items {
    border-radius: 40px;
    cursor: pointer;
    background-color: white;
  }
  
  .select-items:hover {
    transform: translateY(2px);
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);    color: white !important; 
  }
  .bg-color {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);  }
  
  .box-allignment{
    background-color:#FF728B ;
    width: 200px;
    height: 200px;
    padding: 20px;

  }
  @media screen and (max-width: 600px) {
    .box-allignment {
      padding: 10px;
      width: 120px;
      height: 120px;
    }
  }