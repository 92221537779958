@media(max-width:1024px) {
}
@media(max-width:992px) {
    .sm-padding {
    padding: 15px;
}
.header-section .navbar-brand {
    height: 80px;
    padding: 0;
    display: flex;
    align-items: center;
}
.menu-btn, .js #mainmenu {
    display: none;
}
.about-promo {
    padding-left: 0;
}
.project-2 .project-item, .js .slicknav_menu {
    display: block;
}
.error-content .error-info {
    padding-left: 10px;
}
.counter-content {
    text-align: left;
    padding-left: 70px;
}
.counter-wrap .col-lg-4:last-child {
    margin-top: 50px;
}
.projects-carousel .owl-nav div, .service-carousel .owl-nav div {
    left: 0;
}
.projects-carousel .owl-nav div.owl-next, .service-carousel .owl-nav div.owl-next {
    right: 0;
}
.work-pro-item .number-line {
    display: none;
}
.section-heading h2 {
    font-size: 22px;
    line-height: 26px;
}
.cta-content h2 {
    font-size: 36px;
}
.rate-item{
   padding: 5px 5px; 
   border-radius:5px 5px;
}

.rate-item h1{
    font-size: 40px;
    font-family: 'Poppins';
    font-weight: bold;
    text-align: center;
}
.rate-item p{
    font-size:35px;
    
}
.wrapper {
    margin-top: 80px;
    /*margin-bottom: 80px;*/
    background-color: #f9fafa;
    padding: 30px;
    position: relative; /* Needed for positioning the pseudo-element */
    border-radius: 26px;
}

.wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5px; /* Width of the left border */
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    border-radius: 26px; /* Match the wrapper's border-radius */
}

.service-item{
    height:250px;
}


}@media all and (min-width:480px) and (max-width:768px) {
}
@media all and (max-width:767px) {
    body, p {
    font-size: 18px;
    line-height: 24px;
}
h1 {
    font-size: 30px;
}
h2 {
    font-size: 22px;
}
h3 {
    font-size: 16px;
}
p br {
    display: none;
}
.padding {
    padding: 60px 0;
}
.xs-padding {
    padding: 15px;
}
.page-header {
    height: 250px;
}
.page-content h2 {
    font-size: 30px;
    margin: 0;
}
.about-content h2 {
    font-size: 24px;
    line-height: 36px;
}
.cta-content h2 {
    font-size: 28px;
    line-height: 1.2;
}
.error-content {
    display: block;
}
.error-content .error-info h2 {
    font-size: 120px;
    line-height: 120px;
    margin-bottom: 30px;
}
.error-content .error-info a {
    display: block;
}
.contact-form .form-group .col-sm-6:first-child {
    margin-bottom: 15px;
}
.contact-info h2 {
    line-height: 28px;
}
.sidebar-wrap {
    padding-left: 0;
}
.blog-single-content h2 a {
    font-size: 22px;
    line-height: 28px;
}
.blog-single-content h2 {
    line-height: 28px;
}
blockquote p {
    line-height: 32px;
}
.work-pro-item {
    padding: 0 30px;
}
.service-content h2 {
    line-height: 28px;
}
.service-section.section-2 {
    padding-bottom: 60px;
}
.services-list .offset-top {
    transform: translateY(0);
}
#scroll-to-top {
    width: 35px;
    height: 35px;
    line-height: 35px;
    bottom: 20px;
    right: 20px;
}
.projects-section .col-lg-4.text-right {
    text-align: left!important;
}
.projects-carousel {
    margin-top: 40px;
}
.project-single-carousel {
    margin-bottom: 40px;
}
.project-single-content {
    padding-left: 0;
}
}@media all and (max-width:580px) {
    .subscribe-form {
    width: 100%}
.counter-wrap .col-lg-4:first-child {
    margin-bottom: 50px;
}
.counter-content {
    text-align: center;
    padding-left: 0;
}
.testi-item .fa-quote-right {
    display: none;
}
}@media all and (max-width:420px) {
}
@media all and (max-width:380px) {
}
