    @import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro|Poppins:600,700|Work+Sans:600&amp;ampdisplay=swap';
* {
    padding:0;
    margin:0
}
body {
    background-color:#000;
    font-family:source sans pro,sans-serif;
    font-size:16px;
    line-height:26px;
    color:#343131;
    font-weight:400;
    letter-spacing:-.2px;
    position:relative;
    overflow-x:hidden
}
::-webkit-scrollbar {
    width:8px;
    height:8px
}
::-webkit-scrollbar-thumb {
    cursor:pointer;
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
}
::selection {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    color:#fff
}
-webkit-::selection {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    color:#fff
}
::-moz-selection {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    color:#fff
}
h1,h2,h3,h4,h5,h6 {
    font-family:poppins,sans-serif;
    -webkit-font-smoothing:antialiased;
    color:#263a4f
}
.bg-dark h1,.bg-dark h2,.bg-dark h3,.bg-dark h4,.bg-dark h5 {
    color:#fff
}
.bg-dark p,.bg-dark span {
    color:#999
}
h1 {
    font-size:45px;
    font-weight:400;
    line-height:55px;
    margin:0 0 10px;
    color:#263a4f
}
h2 {
    font-size:32px;
    line-height:42px;
    color:#fff;
    margin:0 0 10px;
    font-weight:600;
    letter-spacing:-1px
}
h3,h4 {
    margin:0 0 10px;
    font-weight:400;
    line-height:1.7;
    color:#263a4f;
    letter-spacing:-.5px
}
h3 {
    font-size:20px
}
h4 {
    font-size:16px;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
h5,h6 {
    font-size:14px;
    margin:0 0 10px
}
img {
    border:none;
    outline:none;
    max-width:100%
}
ul {
    display:block;
    list-style:none;
    padding:0;
    margin:0
}
p,li,a,span {
}
p {
    font-size:20px;
    line-height:26px;
    margin-bottom:15px;
    color:#000;
}
.swiper-button-prev, .swiper-button-next {
    /* color:#A18CD1 !important; */
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%) !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    color: transparent !important;
}

a,a:hover {
    text-decoration:none
}
a:focus {
    outline:0;
    text-decoration:none
}
input::-webkit-input-placeholder,textarea::-webkit-input-placeholder {
    color:#ddd!important
}
input:-moz-placeholder,textarea:-moz-placeholder {
    color:#ddd!important
}
input::-moz-placeholder,textarea::-moz-placeholder {
    color:#ddd!important
}
input:-ms-input-placeholder,textarea:-ms-input-placeholder {
    color:#ddd!important
}
button {
    border:none;
    background:0 0
}
.padding {
    padding:100px 0
}
.no-padding {
    padding:0
}
.padding-15 {
    padding:15px
}
.padding-20 {
    padding:50px 0px
}
.box-padding {
    padding:0 50px
}
.bg-white {
    background-color:#fff
}
.bg-grey {
    background-color:#f9fafa
}
.bg-dark {
    background-color:#242323!important
}
.bd-top {
    border-top:1px solid #e5e5e5
}
.bd-bottom {
    border-bottom:1px solid #e5e5e5
}
.mb-10 {
    margin-bottom:10px
}
.mb-15 {
    margin-bottom:15px
}
.mb-20 {
    margin-bottom:20px
}
.mb-25 {
    margin-bottom:25px
}
.mb-30 {
    margin-bottom:30px
}
.mb-35 {
    margin-bottom:35px
}
.mb-40 {
    margin-bottom:40px
}
.mb-45 {
    margin-bottom:45px
}
.mb-50 {
    margin-bottom:50px
}
.ml-15 {
    margin-left:15px
}
.ml-20 {
    margin-left:20px
}
.ml-25 {
    margin-left:25px
}
.ml-30 {
    margin-left:30px
}
.ml-35 {
    margin-left:35px
}
.mt-20 {
    margin-top:20px
}
.mt-30 {
    margin-top:30px
}
.mt-40 {
    margin-top:40px
}
.mt-50 {
    margin-top:50px
}
.fz-28 {
    font-size:28px
}
.fz-24 {
    font-size:24px
}
.fz-22 {
    font-size:22px
}
.fz-20 {
    font-size:20px
}
.fz-18 {
    font-size:18px
}
.fz-16 {
    font-size:16px
}
.text-black {
    color:#333
}
.text-white {
    color:#fff
}
.box-shadow,.box-shadow-hover:hover {
    -webkit-box-shadow:0 .2rem 2.8rem rgba(36,36,36,.1);
    -moz-box-shadow:0 .2rem 2.8rem rgba(36,36,36,.1);
    box-shadow:0 .2rem 2.8rem rgba(36,36,36,.1)
}
.loaded .site-preloader-wrap {
    opacity:0;
    visibility:hidden
}
.site-preloader-wrap {
    position:fixed;
    z-index:999;
    height:100%;
    width:100%;
    background:#fff;
    top:0;
    left:0
}
.site-preloader-wrap .spinner {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    position:absolute;
    left:50%;
    top:50%;
    margin-left:-20px;
    margin-top:-20px
}
.spinner {
    width:40px;
    height:40px;
    border-radius:100%;
    -webkit-animation:sk-scaleout 1s infinite ease-in-out;
    animation:sk-scaleout 1s infinite ease-in-out
}
@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform:scale(0)
    }
    100% {
        -webkit-transform:scale(1);
        opacity:0
    }
}
@keyframes sk-scaleout {
    0% {
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    100% {
        -webkit-transform:scale(1);
        transform:scale(1);
        opacity:0
    }
}
.btn_group {
}
.btn_group a {
    margin:5px
}
.btn_group_left a {
    margin-right:15px;
    margin-bottom:15px
}
.default-btn {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    text-transform:uppercase;
    font-family:work sans,sans-serif;
    color:black;
    font-size:13px;
    border-radius:2px;
    line-height:45px;
    font-weight:bold !important;
    display:inline-block;
    letter-spacing:.5px;
    padding:0 30px;
    -webkit-box-shadow:0 3px 10px rgba(71,44,173,.2);
    box-shadow:0 3px 10px rgba(71,44,173,.2);
    cursor: pointer;
}

.section-heading h2 {
    font-size:32px;
    display:block;
    color:#000;
}
.section-heading span {
    display:inline-block;
    text-transform:uppercase;
    font-size:12px;
    color:#263a4f;
    font-weight:600;
    letter-spacing:0;
    font-family:work sans,sans-serif;
    position:relative;
    margin-bottom:10px;
    z-index:1
}
.section-heading span:before {
    background-color: transparent;
    position: absolute;
    content: "";
    width: 20px;
    height: 30px;
    left: calc(50% - 10px);
    top: calc(50% - 15px);
    border: 3px solid transparent; /* Make the border transparent */
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%); /* Apply gradient as background */
    background-clip: padding-box; /* Prevents the background from bleeding under the border */
    box-sizing: border-box; /* Includes padding and border in the width/height */
    opacity: .5;
    z-index: -1;
}

a,a:hover,.overlay,img,.form-control,.form-control:hover,button {
    -webkit-transition:all .5s;
    -o-transition:all .5s;
    transition:all .5s
}
.header {
    background-color:#fff;
    width:100%;
    height:auto;
    display:block
}
.viewport-sm .primary-header {
    padding:10px 0;
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
}
.header-menu-wrap {
    margin-left:auto
}
.sticky-header {
    width:100%;
    position:fixed;
    left:0;
    top:0;
    transform:translateY(-100%);
    z-index:99
}
.sticky-header .primary-header {
    background-color:#fff;
    box-shadow:0 2px 5px -2px rgba(0,0,0,.1)
}
.sticky-header.headroom--top {
    transform:translateY(-100%)
}
.headroom {
    transition:transform .25s ease-in-out;
    will-change:transform;
}
.headroom--unpinned {
    transform:translateY(-100%)
}
.headroom--pinned {
    transform:translateY(0)
}
.header ul {
    margin:0;
    padding:0;
    list-style:none
}
.mobile-menu-icon {
    display:none
}
.burger-menu {
    width:20px;
    height:17px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    cursor:pointer;
    -webkit-transition:transform 330ms ease-out;
    -moz-transition:transform 330ms ease-out;
    -o-transition:transform 330ms ease-out;
    transition:transform 330ms ease-out
}
.burger-menu.menu-open {
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    -o-transform:rotate(-45deg);
    transform:rotate(-45deg)
}
.line-menu {
    background-color:#000;
    border-radius:2px;
    width:100%;
    height:4px
}
.line-menu.line-half {
    width:50%
}
.line-menu.first-line {
    transition:transform 330ms cubic-bezier(.54,-.81,.57,.57);
    transform-origin:right
}
.menu-open .line-menu.first-line {
    -webkit-transform:rotate(-90deg) translateX(3px);
    -moz-transform:rotate(-90deg) translateX(3px);
    -o-transform:rotate(-90deg) translateX(3px);
    transform:rotate(-90deg) translateX(3px)
}
.line-menu.last-line {
    align-self:flex-end;
    transition:transform 330ms cubic-bezier(.54,-.81,.57,.57);
    transform-origin:left
}
.menu-open .line-menu.last-line {
    -webkit-transform:rotate(-90deg) translateX(-3px);
    -moz-transform:rotate(-90deg) translateX(-3px);
    -o-transform:rotate(-90deg) translateX(-3px);
    transform:rotate(-90deg) translateX(-3px)
}
.header-menu-wrap ul {
    margin:0;
    padding:0;
    list-style:none
}
.header-menu-wrap ul li {
    display:inline-block;
    position:relative
}
.header-menu-wrap ul li>a {
    display:block;
    font-family:work sans,sans-serif;
    font-size:12px;
    letter-spacing:0;
    text-transform:uppercase;
    font-weight:600;
    color:#000;
    padding:0 20px;
    height:80px;
    line-height:80px;
    text-decoration:none;
    -webkit-font-smoothing:antialiased
}
.header-menu-wrap ul li:hover>a {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.header-menu-wrap li ul {
    background-color:#fff;
    display:block;
    width:250px;
    padding:30px 0;
    -webkit-box-shadow:3px 1px 20px 0 rgba(0,0,0,.07);
    box-shadow:3px 1px 20px 0 rgba(0,0,0,.07);
    position:absolute;
    left:-35px;
    top:100%;
    opacity:0;
    visibility:hidden;
    z-index:0;
    -webkit-transition:opacity .5s ease,visibility .5s ease;
    -o-transition:opacity .5s ease,visibility .5s ease;
    transition:opacity .5s ease,visibility .5s ease
}
.header-menu-wrap li:hover>ul {
    opacity:1;
    visibility:visible;
    z-index:99
}
.header-menu-wrap li li {
    display:block;
    padding:0 35px;
    margin-bottom:5px;
    text-align:left;
    position:relative
}
.header-menu-wrap li li:last-child {
    margin:0
}
.header-menu-wrap li li>a {
    font-family:source sans pro,sans-serif;
    display:block;
    height:auto;
    line-height:inherit;
    color:#263a4f;
    font-weight:400;
    letter-spacing:0;
    line-height:2.5;
    text-transform:capitalize;
    padding:1px 8px;
    font-size:14px;
    -webkit-font-smoothing:antialiased
}
.header-menu-wrap li li:hover>a {
    color:#A18CD1!important;
    background-color:#f9f9f9
}
.header-menu-wrap li ul li ul {
    width:250px;
    position:absolute;
    left:100%;
    top:0
}
@media(min-width:993px) {
    .header-menu-wrap li ul {
        display:block!important
    }
}
@media(max-width:992px) {
    .headroom--top .header-menu-wrap,.headroom--unpinned .header-menu-wrap {
        display:none!important
    }
    .dropdown-plus {
        width:49px;
        height:49px;
        line-height:49px;
        position:absolute;
        top:0;
        right:0;
        cursor:pointer
    }
    .dropdown-plus:before,.dropdown-plus:after {
        position:absolute;
        content:'';
        top:24px;
        right:18px;
        width:13px;
        height:1px;
        background-color:#222
    }
    .dropdown-plus:after {
        -webkit-transform:rotate(90deg);
        -moz-transform:rotate(90deg);
        -o-transform:rotate(90deg);
        transform:rotate(90deg)
    }
    .dropdown-plus.dropdown-open:after {
        display:none
    }
    .mobile-menu-icon {
        display:block
    }
    .header-menu-wrap {
        display:none;
        background-color:#fff;
        width:100%;
        height:auto;
        padding:0 20px;
        position:absolute;
        left:0;
        top:50px;
        z-index:999
    }
    .header-menu-wrap ul li {
        display:block;
        border-bottom:1px solid rgba(0,0,0,.04)
    }
    .header-menu-wrap ul li:first-child {
        border-top:1px solid rgba(0,0,0,.04)
    }
    .header-menu-wrap ul li>a {
        padding:10px 15px;
        height:inherit;
        line-height:inherit
    }
    .header-menu-wrap ul li ul li ul,.header-menu-wrap ul li ul {
        background-color:transparent;
        width:100%;
        opacity:1;
        padding:0;
        visibility:visible;
        position:inherit;
        display:none;
        top:inherit;
        left:inherit;
        box-shadow:none
    }
    .header-menu-wrap li li {
        padding-left:11px
    }
    .header-menu-wrap li li:last-child {
        border-bottom:none
    }
    .header-menu-wrap li li>a {
        color:#263a4f;
        font-size:13px
    }
    .header-menu-wrap li li:hover>a {
        color:#666
    }
}
@media(max-width:580px) {
    .header-right a.menu-btn {
        line-height:40px;
        font-size:10px;
        padding:0 25px
    }
    .primary-header .header-logo {
        max-width:150px
    }
    .img-service {
    border: 2px solid #A18CD1;
    border-radius: 50%;
    padding: 5px;
    background: #A18CD1;
    width:100px !important;
    height: 100px !important;
}
}
.primary-header-inner .header-right {
    display:flex;
    align-items:center;
    line-height:80px;
    line-height: 79px;
    margin-left: 3px;
    flex-direction: row-reverse;
    flex-wrap: wrap;

}
.header-right .mobile-menu-icon {
    margin-left:0px;
    margin-right:160px;

}
.header-right a.menu-btn {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    line-height: 30px;
    color: black;
    font-weight: bold;
    border-radius: 12px;
    /* margin: 0 auto; */
    display: flex;
    align-items: center;
    padding: 8px 42px;
    font-size: 21px;
}

.header-right a.menu-btn:hover {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    opacity:.8;
    color:black
}
.primary-header-inner {
    display:flex;
    align-items:center;
    justify-content:space-between;
    position:relative
}
.primary-header .header-logo {
    max-width:180px
}
.viewport-lg .primary-header .header-menu-wrap {
    display:block!important
}
.about-section {
}
.about-wrap {
    display:flex;
    align-items:center
}
.about-content {
}
.about-content h2 {
    margin-bottom:20px
}
.about-content p {
    margin-bottom:30px
}
.about-promo {
    padding-left:80px
}
.about-promo .about-promo-item {
    display:flex;
    align-items:center;
    margin-bottom:15px
}
.about-promo .about-promo-item:last-child {
    margin-bottom:0
}
.about-promo .about-promo-item i {
    font-size:70px;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-right:30px
}
.service-section {
    position:relative;
    z-index:1
}
.service-section .dark-bg {
    position:absolute;
    background-color:#232427;
    width:100%;
    height:350px;
    left:0;
    top:0;
    z-index:-2;
    padding-top:80px
}
.service-section:before {
    background-image:url(../img/map-pattern.png);
    background-repeat:no-repeat;
    background-position:top center;
    background-size:contain;
    position:absolute;
    content:"";
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:-1
}
.section-heading.dark-background h2 {
    color:#fff
}
.section-heading.dark-background span {
    color:#ddd
}
.service-item {
    background-color:#fff;
    padding:15px 20px;
    border-right:1px solid rgba(0,0,0,.03);
    transition:all .2s ease-in-out;
    text-align:center;
    position:relative;
    z-index:1;
    border-radius:20px;
    /*height:250px;*/
}
.service-item .service-icon {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size:60px;
    margin-bottom:10px
}
.service-item .overlay-icon {
    position:absolute;
    left:50%;
    bottom:50%;
    font-size:130px;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    opacity:.09;
    transform:translate(-50%,50%);
    z-index:-1
}
.service-item h3 {
    font-size:18px;
    line-height:normal;
}
.service-item .read-more {
    font-family:work sans,sans-serif;
    color:#263a4f;
    font-size:12px;
    font-weight:600;
    text-transform:uppercase;
    position:relative
}
.read-more{
    cursor: pointer;
}
.service-item .read-more:hover {
    opacity:.8;
}
.service-item:hover {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);    transition:all .2s ease-in-out
}
.decoration:checked + .label-for-check .service-item {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    transition:all .2s ease-in-out
}

.service-item:hover h3,.service-item:hover .service-icon,.service-item:hover .overlay-icon,.service-item:hover p,.service-item:hover .read-more {
    color:#fff;
    transition:all .2s ease-in-out
}
.service-item:hover h4,.service-item:hover .service-icon,.service-item:hover .overlay-icon,.service-item:hover p,.service-item:hover .read-more {
    color:#fff;
    transition:all .2s ease-in-out
}
.service-carousel {
    position:relative
}
.service-carousel .owl-nav {
}
.service-carousel .owl-nav div {
    position:absolute;
    width:50px;
    height:50px;
    line-height:50px;
    text-align:center;
    left:-70px;
    top:calc(50% - 20px);
    background-color:#fff;
    color:#263a4f;
    border-radius:2px;
    box-shadow:0 1px 3px rgba(0,0,0,.1)
}
.service-carousel .owl-nav div.owl-next {
    left:auto;
    right:-70px
}
.service-section.section-2 {
    position:relative;
    z-index:1;
    padding-bottom:100px
}
.service-section.section-2 .dots {
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    background:url(../img/bg-dots.png) 0 0 repeat;
    z-index:-1
}
.service-content {
}
.service-content span {
    font-family:work sans,sans-serif;
    font-size:12px;
    font-weight:600;
    text-transform:uppercase;
    margin-bottom:15px;
    display:block
}
.service-content h2 {
    margin-bottom:20px
}
.service-content p {
    margin-bottom:30px
}
.services-list .offset-top {
    transform:translateY(30px)
}
.services-list .service-item {
    text-align:left
}
.services-list .service-item i {
    font-size:50px;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom:10px;
    display:block
}
.services-list .service-item:hover i {
    color:#fff
}
.services-list .service-item h3 {
    margin:0 0 5px
}
.services-list .service-item p {
    font-size:14px;
    line-height:1.6;
    margin:0
}
.promo-section {
}
.promo-wrap {
}
.promo-item {
    padding:30px 15px
}
.promo-item h3 {
    font-size:18px;
    margin:0 0 5px
}
.promo-item i {
    display:block;
    font-size:50px;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom:10px
}
.promo-item p {
    font-size:14px;
    line-height:1.6;
    margin-bottom:0
}
.promo-item:hover {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    transition:all .2s ease-in-out
}
.promo-item:hover h3,.promo-item:hover .service-icon,.promo-item:hover .overlay-icon,.promo-item:hover p,.promo-item:hover .read-more {
    color:#fff;
    transition:all .2s ease-in-out
}
.work-pro-section {
    background-image:url(../img/d-slide-6.html);
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
    position:relative;
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:1
}
.work-pro-section:before {
    background-color:rgba(35,36,39,.8);
    position:absolute;
    content:"";
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:-1
}
.work-pro-item {
}
.work-pro-item .number {
    font-size:18px;
    color:#fff;
    font-family:poppins,sans-serif;
    font-weight:600;
    width:50px;
    height:50px;
    line-height:50px;
    text-align:center;
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    display:inline-block;
    border-radius:50%;
    margin-bottom:20px;
    position:relative;
    z-index:1
}
.work-pro-item .number-line {
    background-color:rgba(255,255,255,.08);
    position:absolute;
    content:"";
    width:100%;
    height:2px;
    top:25px;
    left:150px;
    z-index:-1
}
.work-pro-item h3 {
    color:#fff;
    font-size:18px
}
.work-pro-item p {
    color:#ddd;
    margin:0
}
.projects-section {
}
.projects-section .container-fluid {
    padding:0
}
.projects-carousel {
}
.project-item {
    position:relative;
    overflow:hidden;
    z-index:1
}
.project-item img {
    width:100%
}
.project-item .overlay {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    content:"";
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    display:block;
    visibility:hidden;
    opacity:0;
    z-index:1
}
.project-item:hover .overlay {
    visibility:visible;
    opacity:1;
    cursor:pointer;
    transition:all 300ms ease
}
.projects-content {
    position:absolute;
    width:100%;
    height:auto;
    left:0;
    bottom:0;
    padding:30px;
    z-index:1;
    -webkit-transform:translateY(100%);
    transform:translateY(100%);
    -webkit-transition:all .4s cubic-bezier(.37,.31,.2,.85);
    transition:all .4s cubic-bezier(.37,.31,.2,.85)
}
.project-item:hover .projects-content {
    transform:translateY(0)
}
.project-item .tittle:hover {
    text-decoration:underline
}
.projects-content .category {
    font-family:work sans,sans-serif;
    text-transform:uppercase;
    font-weight:500;
    font-size:12px;
    margin-bottom:15px;
    color:#fff;
    display:block
}
.projects-content .tittle {
    font-size:18px;
    color:#fff;
    line-height:26px;
    font-weight:600;
    letter-spacing:0;
    display:block
}
.project-item .view-icon {
    position:absolute;
    width:auto;
    height:auto;
    right:40px;
    top:30px;
    display:block;
    font-size:25px;
    color:#fff;
    visibility:hidden;
    opacity:0;
    -webkit-transition:all 400ms ease;
    -o-transition:all 400ms ease;
    transition:all 400ms ease
}
.project-item:hover .view-icon {
    cursor:pointer;
    right:20px;
    visibility:visible;
    opacity:1;
    z-index:1
}
.projects-carousel {
    position:relative
}
.projects-carousel .owl-nav {
}
.projects-carousel .owl-nav div {
    position:absolute;
    width:50px;
    height:50px;
    line-height:50px;
    text-align:center;
    left:-70px;
    top:calc(50% - 20px);
    background-color:#fff;
    color:#263a4f;
    border-radius:2px;
    box-shadow:0 1px 3px rgba(0,0,0,.1)
}
.projects-carousel .owl-nav div.owl-next {
    left:auto;
    right:-70px
}
.project-single-section {
    position:relative;
    z-index:1
}
.project-single-section .dots {
    width:100%;
    height:100%;
    left:0;
    top:0;
    background:url(../img/bg-dots.png) 0 0 repeat;
    z-index:-1
}
.project-single-content {
    background-color:#fff;
    padding-left:30px
}
.project-single-content p,.project-single-content h2 {
    margin-bottom:20px
}
.project-single-content .project-details li {
    line-height:40px
}
.project-single-content .project-details span {
    font-family:work sans,sans-serif;
    text-transform:uppercase;
    font-weight:600;
    color:#263a4f;
    font-size:14px;
    letter-spacing:0;
    width:150px;
    display:inline-block
}
.cta-section {
    background-image:url(../img/6.png);
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
    position:relative;
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:1
}
.cta-section:before {
    background-color:rgba(35,36,39,.8);
    position:absolute;
    content:"";
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:-1
}
.cta-content {
}
.cta-content span {
    font-family:work sans,sans-serif;
    font-size:12px;
    font-weight:600;
    text-transform:uppercase;
    margin-bottom:15px;
    display:block;
    color:#ddd
}
.cta-content h2 {
    color:#fff;
    font-size:32px;
    line-height:46px;
    margin-bottom:30px
}
.content-section {
}
.content-wrap {
    display:flex;
    align-items:center
}
.content-wrap img {
    border-radius:5px
}
.content-info {
}
.content-info span {
    font-family:work sans,sans-serif;
    font-size:12px;
    font-weight:600;
    text-transform:uppercase;
    margin-bottom:15px;
    display:block;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.content-info h2 {
    margin-bottom:20px
}
.content-info p {
    margin-bottom:30px
}
.counter-section {
    background-image:url(../img/5.html);
    background-repeat:no-repeat;
    background-position:center bottom;
    background-size:cover;
    position:relative;
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:1
}
.counter-section:before {
    background-color:rgba(35, 36, 39, 0.477);
    position:absolute;
    content:"";
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:-1
}
.counter-content {
    text-align:center
}
.counter-content .counter {
    font-family:work sans,sans-serif;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size:48px;
    line-height:1;
    font-weight:600;
    position:relative;
    display:block;
    margin:0 0 10px
}
.counter-content h4 {
    font-family:work sans,sans-serif;
    font-size:12px;
    color:#fff;
    font-weight:600;
    display:block;
    text-transform:uppercase
}
.team-section {
    position:relative;
    z-index:1
}
.team-section .dots {
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    background:url(../img/bg-dots.png) 0 0 repeat;
    z-index:-1
}
.team-wrap .col-lg-3 {
    padding:0
}
.team-item {
    position:relative;
    overflow:hidden;
    z-index:1
}
.team-item img {
    width:100%
}
.team-item .team-content {
    display:block
}
.team-item .team-content h3 {
    position:absolute;
    width:auto;
    height:auto;
    left:0;
    bottom:-58px;
    background-color:#fff;
    box-shadow:0 14px 70px -10px rgba(0,0,0,.15);
    transform:rotate(-90deg);
    transform-origin:0 0;
    padding:10px 15px;
    font-size:16px;
    z-index:2
}
.bg-dark .team-item .team-content h3 {
    color:#263a4f
}
.team-item .team-content span {
    position:absolute;
    width:auto;
    height:auto;
    right:20px;
    bottom:20px;
    color:#fff;
    font-size:10px;
    letter-spacing:1px;
    font-family:work sans,sans-serif;
    padding:5px 10px;
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    box-shadow:0 14px 70px -10px rgba(0,0,0,.15);
    text-transform:uppercase;
    font-weight:600;
    z-index:2
}
.team-item .team-social {
    position:absolute;
    width:auto;
    height:auto;
    right:20px;
    top:20px;
    visibility:hidden;
    opacity:0;
    z-index:2;
    transition:all .3s linear
}
.team-item .team-social li {
    transform:translateX(100px);
    transition:all .3s linear
}
.team-item .team-social li:nth-child(1) {
    transition-delay:0ms
}
.team-item .team-social li:nth-child(2) {
    transition-delay:100ms
}
.team-item .team-social li:nth-child(3) {
    transition-delay:150ms
}
.team-item .team-social li:nth-child(4) {
    transition-delay:200ms
}
.team-item:hover .team-social li {
    transform:translateX(0)
}
.team-item .team-social li a {
    color:#fff;
    margin-bottom:10px;
    display:block
}
.team-item:hover img {
    transform:scale(1.08);
    cursor:pointer
}
.team-item:hover .team-social {
    visibility:visible;
    opacity:1
}
.team-item .overlay {
    background-color:rgba(0,0,0,.2);
    position:absolute;
    content:"";
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:1;
    visibility:hidden;
    opacity:0
}
.team-item:hover .overlay {
    visibility:visible;
    opacity:1
}
.testimonial-section {
    position:relative;
    z-index:1
}
.testimonial-section .dots {
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    background:url(../img/bg-dots.png) 0 0 repeat;
    z-index:-1
}
.testi-item img {
    width:100px!important;
    height:100px;
    line-height:100px;
    border-radius:50%
}
.testi-content {
    padding-left:20px
}
.testi-content h3 {
    font-size:16px;
    display:inline-block;
    font-weight:600;
    margin:0
}
.testi-content p {
    color:#000;
}
.testi-content span {
    display:block;
    font-size:12px;
    text-transform:uppercase;
    font-family:work sans,sans-serif
}
.testi-item {
    background-color: #fff;
    position: relative;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
}

.testi-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px; 
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
}

.testi-item .fa-quote-right {
    position:absolute;
    right:30px;
    bottom:25px;
    font-size:50px;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    opacity:.5
}
.testi-content .rattings {
    display:inline-block;
    margin-left:5px
}
.testi-content .rattings li {
    display:inline-block
}
.testi-content .rattings li i {
    font-size:10px;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display:inline-block
}
.owl-controls .owl-dots {
    width:100%;
    height:auto;
    position:absolute;
    left:0;
    bottom:-50px;
    text-align:center
}
.owl-controls .owl-dots .owl-dot {
    display:inline-block;
    width:10px;
    height:10px;
    border-radius:1px;
    background-color:#555;
    margin:0 3px;
    position:relative;
    text-align:center
}
.owl-controls .owl-dots .owl-dot.active {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);

}
.page-header {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
    width:100%;
    height:180px;
    display:flex;
    align-items:center;
    position:relative;
    z-index:1
}
.page-header:before {
    background-color:rgba(0,0,0,.1);
    position:absolute;
    content:"";
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:-1
}
.page-content {
}
.page-content h2 {
    color:#fff;
    font-size:36px
}
.page-content p {
    color:#ddd;
    margin:0
}
.error-section {
}
.error-content {
    display:-webkit-flex;
    display:-moz-flex;
    display:-ms-flex;
    display:-o-flex;
    display:flex;
    align-items:center;
    justify-content:center
}
.error-content img {
    max-width:100%
}
.error-content .error-info {
    padding-left:50px;
    text-align:center
}
.error-content .error-info h2 {
    font-size:200px;
    line-height:200px;
    letter-spacing:-10px
}
.error-content .error-info a {
    color:#263a4f;
    font-weight:600;
    text-decoration:underline
}
.error-content .error-info a:hover {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.contact-section {
    position:relative;
    z-index:1
}
.contact-section .dots {
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    background:url(../img/bg-dots.png) 0 0 repeat;
    z-index:-1
}
.contact-info h3 {
    line-height:28px
}
.contact-info h4 {
    font-size:15px;
    line-height:28px
}
.contact-info span {
    text-transform:uppercase;
    margin-right:5px
}
.form-control {
    background-color:#fff;
    border-radius:0;
    padding:15px 10px;
    box-shadow:none;
    border-radius:5px;
}
.form-control:focus {
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%) 1;    box-shadow:none;
    outline:none
}
#form-messages {
    display:none
}
#form-messages.alert-danger,#form-messages.alert-success {
    display:block
}
#google-map {
    width:100%;
    height:400px
}
.blog-section {
}
.blog-section.bg-dark .dots {
    width:100%;
    height:100%;
    left:0;
    top:0;
    background:url(../img/bg-dots.png) 0 0 repeat;
    z-index:-1
}
.blog-item .blog-thumb {
    position:relative
}
.blog-item .blog-thumb .category {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    padding:5px 15px;
    text-align:center;
    color:#fff;
    font-size:12px;
    text-transform:uppercase;
    font-weight:600;
    font-family:work sans,sans-serif;
    position:absolute;
    width:auto;
    height:auto;
    left:0;
    bottom:0
}
.blog-item .blog-thumb .category a {
    color:#fff
}
.blog-item .blog-thumb img {
    width:100%;
    margin:0
}
.blog-content {
    background-color:#f9fafa;
    padding:30px;
    border-radius:5px;
}
.blog-content h3 {
    line-height:24px
}
.blog-content h3 a {
    color:#263a4f;
    font-size:18px;
    line-height:24px
}
.blog-content h3 a:hover {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.blog-content .read-more {
    font-family:work sans,sans-serif;
    color:#263a4f;
    font-size:12px;
    font-weight:600;
    text-transform:uppercase;
    position:relative;
    padding-left:30px;
    transition:all 200ms linear 0ms
}
.blog-content .read-more:before {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    position:absolute;
    content:"";
    width:20px;
    height:2px;
    left:0;
    top:calc(50% - 1px);
    transition:all 200ms linear 0ms
}
.blog-content .read-more:hover {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    padding-left:40px
}
.blog-content .read-more:hover:before {
    width:30px
}
.pagination-wrap {
}
.pagination-wrap li {
    display:inline-block;
    margin:0 5px
}
.pagination-wrap.text-left li {
    margin:0 10px 0 0
}
.pagination-wrap li a {
    border:1px solid #e5e5e5;
    display:inline-block;
    width:40px;
    height:40px;
    line-height:38px;
    text-align:center;
    color:#263a4f;
    font-weight:600;
    border-radius:2px
}
.pagination-wrap li a:hover {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    color:#fff;
    opacity:1;
    text-decoration:none
}
.pagination-wrap li a.active {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    border:1px solid #A18CD1;
    color:#fff
}
.sidebar-wrap {
    padding-left:40px
}
.sidebar-wrap .widget-content {
    margin-bottom:40px
}
.sidebar-wrap .widget-content h4 {
    position:relative;
    margin-bottom:30px
}
.sidebar-wrap .widget-content h4:before {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    position:absolute;
    content:"";
    width:20%;
    height:3px;
    left:0;
    bottom:-5px
}
.sidebar-wrap .widget-content .widget-links li a:hover {
    text-decoration:underline
}
.search-form {
    position:relative;
    margin-left:-2px
}
.search-form .form-control {
    background-color:#f5f5f5;
    box-shadow:none;
    width:100%;
    display:block;
    border:1px solid #e5e5e5;
    color:#263a4f;
    height:auto;
    padding:15px 20px;
    border-radius:2px;
    padding-right:60px
}
.search-form .search-btn {
    background-color:transparent;
    font-size:15px;
    color:#263a4f;
    width:60px;
    height:100%;
    position:absolute;
    top:0;
    right:0;
    display:block;
    padding:10px 0;
    opacity:.6
}
.search-form .search-btn:focus,.search-form .search-btn:hover {
    opacity:1;
    cursor:pointer
}
.search-form input::-webkit-input-placeholder {
    color:#8d9aa8!important
}
.search-form input:-moz-placeholder {
    color:#8d9aa8!important
}
.search-form input::-moz-placeholder {
    color:#8d9aa8!important
}
.search-form input:-ms-input-placeholder {
    color:#8d9aa8!important
}
.thumb-post {
}
.thumb-post li {
    display:-webkit-flex;
    display:-moz-flex;
    display:-ms-flex;
    display:-o-flex;
    display:flex;
    align-items:top;
    margin-bottom:20px
}
.thumb-post li:last-child {
    margin-bottom:0
}
.thumb-post li img {
    width:100px;
    display:block
}
.thumb-post li a {
    font-family:poppins,sans-serif;
    font-size:15px;
    font-weight:600;
    color:#263a4f;
    padding-left:15px;
    letter-spacing:-.5px
}
.thumb-post li a:hover {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-decoration:underline
}
.widget-content .tags {
}
.widget-content .tags li {
    display:inline-block
}
.widget-content .tags li a {
    display:inline-block;
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    padding:5px 10px;
    border-radius:2px;
    font-size:12px;
    color:#fff;
    margin:3px
}
.widget-content .tags li a:hover {
    background-color:#263a4f;
    color:#fff;
    box-shadow:0 1px 3px rgba(0,0,0,.1)
}
.blog-thumb {
}
.blog-thumb img {
    margin-bottom:20px
}
.blog-single-content {
}
.blog-single-content h2 a {
    font-size:28px;
    color:#263a4f
}
.blog-single-content h2 a:hover {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.single-post-meta {
    margin-bottom:20px
}
.single-post-meta li {
    display:inline-block;
    margin-right:20px
}
.single-post-meta li i {
    font-size:22px;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-right:15px
}
.single-post-meta li h3 {
    display:inline-block;
    font-family:work sans,sans-serif;
    text-transform:uppercase;
    font-size:18px;
    color:#263a4f;
    background:#fff;
    border-radius: 5px;
    padding:10px;
    width:200px;
    height:50px;
}
blockquote {
    background-color:#f5f5f5;
    padding:40px;
    border-left:4px solid #A18CD1;
    margin:30px 0;
    position:relative;
    z-index:1
}
blockquote .dots {
    width:100%;
    height:100%;
    left:0;
    top:0;
    background:url(../img/bg-dots.png) 0 0 repeat;
    z-index:-1
}
blockquote p {
    color:#263a4f;
    font-size:20px
}
blockquote span {
    display:block;
    margin-top:20px;
    color:#263a4f
}
.post-tags {
    margin-top:30px
}
.post-tags li {
    display:inline-block
}
.post-tags li a {
    display:inline-block;
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    padding:5px 10px;
    border-radius:2px;
    font-size:12px;
    color:#fff;
    margin:3px
}
.post-tags li a:hover {
    background-color:#263a4f;
    color:#fff;
    box-shadow:0 1px 3px rgba(0,0,0,.1)
}
.author-box {
    display:flex;
    align-items:center;
    padding:30px;
    margin-top:30px;
    border-left:4px solid #A18CD1
}
.author-box img {
    border-radius:50%
}
.author-info {
    padding-left:30px
}
.author-info h3 {
    font-size:18px;
    line-height:28px;
    margin-bottom:5px
}
.author-box .social-icon {
}
.author-box .social-icon li {
    display:inline-block;
    margin-right:8px
}
.author-box .social-icon li a {
    font-size:14px;
    color:#263a4f
}
.author-box .social-icon li a:hover {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.post-navigation {
    border:1px solid #e5e5e5;
    margin-top:40px;
    margin-left:0;
    margin-right:0
}
.post-navigation .col {
    padding:20px
}
.post-navigation .col:not(:last-of-type) {
    border-right:1px solid rgba(17,17,17,.04)
}
.post-navigation .col a {
    color:#263a4f;
    font-size:12px;
    text-transform:uppercase;
    font-family:work sans,sans-serif;
    font-weight:600;
    display:flex;
    align-items:center
}
.post-navigation .col.next-post a {
    justify-content:flex-end
}
.post-navigation .col i {
    display:inline-block;
    font-size:14px
}
.post-navigation .ti-arrow-left {
    margin-right:10px
}
.post-navigation .ti-arrow-right {
    margin-left:10px
}
.post-navigation .col a:hover {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.post-navigation .col.prev-post .fa {
    margin-right:10px
}
.post-navigation .col.next-post .fa {
    margin-left:10px
}
.blog-single-wrap .comments-area {
    margin-top:40px
}
.blog-single-wrap .comments-area .comments {
    border-bottom:0
}
.blog-single-wrap .comments-area li>div {
    border-bottom:1px solid rgba(17,17,17,.05);
    padding:35px
}
.blog-single-wrap .comments-area ol {
    list-style-type:none;
    padding-left:0
}
.blog-single-wrap .comments-area ol ul {
    padding-left:30px;
    list-style-type:none;
    margin:0
}
.blog-single-wrap .comments-area ol>li:last-child div {
    border-bottom:0
}
.blog-single-wrap .comments-area .comments-title {
    font-size:22px;
    font-weight:600
}
.blog-single-wrap .comments-area li>div {
    position:relative
}
.blog-single-wrap .comments-area .comment-thumb {
    position:absolute;
    left:35px
}
.blog-single-wrap .comments-area .comment-thumb .comment-img {
    width:80px;
    height:80px;
    border-radius:50%
}
.blog-single-wrap .comments-area .comment-thumb .comment-img img {
    border-radius:50%
}
.blog-single-wrap .comments-area .comment-main-area {
    padding-left:100px
}
.blog-single-wrap .comments-area .comment-main-area p {
    margin-bottom:20px
}
.blog-single-wrap .comments-area .comments-meta h4 {
    font-family:poppins,sans-serif;
    font-size:18px;
    font-weight:600;
    letter-spacing:-1px
}
.blog-single-wrap .comments-area .comments-meta h4 span {
    font-family:work sans,sans-serif;
    font-weight:600;
    text-transform:none;
    display:inline-block;
    font-size:12px;
    text-transform:uppercase;
    margin-left:5px
}
.blog-single-wrap .comments-area .comment-reply-link {
    font-family:work sans,sans-serif;
    font-size:12px;
    font-weight:600;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display:inline-block;
    text-transform:uppercase;
    padding-left:35px;
    position:relative
}
.blog-single-wrap .comments-area .comment-reply-link:before {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    position:absolute;
    content:"";
    width:30px;
    height:2px;
    left:0;
    top:calc(50% - 1px)
}
.blog-single-wrap .comments-area .comment-reply-link:hover {
    text-decoration:underline
}
.blog-single-wrap .comment-respond {
}
.blog-single-wrap .comment-respond .comment-reply-title {
    font-size:22px;
    font-weight:600;
    letter-spacing:-1px;
    margin-bottom:20px
}
.blog-single-wrap .comment-respond form input,.blog-single-wrap .comment-respond form textarea {
    background-color:#f5f5f5;
    border:1px solid #e5e5e5;
    width:100%;
    height:50px;
    padding:6px 15px;
    margin-bottom:15px;
    outline:0;
    -webkit-box-shadow:none;
    box-shadow:none;
    transition:all .3s
}
.blog-single-wrap .comment-respond form input:focus,.blog-single-wrap .comment-respond form textarea:focus {
    border-color:#A18CD1
}
.blog-single-wrap .comment-respond form textarea {
    height:200px;
    padding:15px
}
.blog-single-wrap .comment-respond .form-inputs {
    overflow:hidden
}
.blog-single-wrap .comment-respond .form-inputs>input:nth-child(2) {
    width:49%;
    float:left
}
.blog-single-wrap .comment-respond .form-inputs>input:nth-child(3) {
    width:49%;
    float:right
}
.blog-single-wrap .comment-respond .form-submit input {
    font-family:work sans,sans-serif;
    max-width:180px;
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    color:#fff;
    font-weight:600;
    font-size:12px;
    letter-spacing:1px;
    margin-bottom:0;
    border:0;
    outline:0;
    cursor:pointer;
    border-radius:0;
    text-transform:uppercase
}
.blog-single-wrap .comment-respond .form-submit input:hover {
    opacity:.9
}
.sponsor-section {
    padding:50px 0;
    position:relative;
    z-index:1
}
.sponsor-section .dots {
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    background:url(../img/bg-dots.png) 0 0 repeat;
    z-index:-1
}
.sponsor-item {
}
.sponsor-item img {
    opacity:.5
}
.sponsor-item:hover img {
    opacity:1;
    cursor:pointer
}
.widget-section {
    background-color:#232427;
    border-bottom:1px solid #333
}
.widget-content {
}
.widget-content img {
    margin-bottom:20px
}
.widget-section .widget-content h4 {
    color:#fff
}
.widget-content p {
}
.widget-content span {
    display:block
}
.widget-content .widget-links {
}
.widget-content .widget-links li {
}
.widget-content .widget-links li a {
    color:#8d9aa8;
    line-height:1.9
}
.widget-content .widget-links li a:hover {
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-decoration:underline
}
.subscribe-form {
    position:relative
}
.subscribe-form .form-input {
    border:1px solid #e5e5e5;
    width:100%;
    height:50px;
    padding:10px;
    padding-right:140px;
    border-radius:2px
}
.subscribe-form .submit-btn {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    font-family:work sans,sans-serif;
    text-transform:uppercase;
    color:#fff;
    font-size:12px;
    border-radius:2px;
    line-height:50px;
    font-weight:600;
    display:inline-block;
    letter-spacing:.5px;
    padding:0 30px;
    position:absolute;
    top:0;
    right:0;
    cursor:pointer
}
.footer-section {
    background-color:#232427;
    display:block;
    color:#fff;
    text-align:center;
    padding:25px 0
}
.footer-section p {
    font-size:12px;
    color:#ababc7;
    margin:0
}
#scroll-to-top {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    display:none;
    width:45px;
    height:45px;
    text-align:center;
    font-size:14px;
    border-radius:50%;
    line-height:45px;
    color:#fff;
    position:fixed;
    bottom:50px;
    right:50px;
    z-index:999
}
#scroll-to-top:hover {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    color:#fff;
    opacity:.8
}


/* Gallery */


.work-section{
	padding:80px 0 0;
}

.work-section .work-item{
	flex:0 0 33.33%;
	max-width: 33.33%;
	padding:15px;
}
.work-section .work-item-inner img{
	width: 100%;
	display: block;
	transform: scale(1.1);
	transition: all 0.5s ease;
}
.work-section .work-item-inner:hover img{
	filter: grayscale(100%);
	transform: scale(1);
}
.work-section .work-item-inner{
	position: relative;
	cursor: pointer;
	overflow:hidden;
}

.work-section .work-item-inner .overlay{
	position: absolute;
	left:0;
	top:0;
	height: 100%;
	width: 100%;
	padding:15px;
	display: flex;
	align-items: flex-end;
	transition: all 0.5s ease;
	background-color: rgba(0,0,0,0.6);
	opacity:0;
}
.work-section .work-item-inner:hover .overlay{
	opacity:1;
}
.work-section .work-item-inner .overlay h4{
	color:#ffffff;
	font-size: 20px;
	margin:0;
	transform: translateX(30px);
	transition: all 0.5s ease;
}
.work-section .work-item-inner:hover .overlay h4{
	transform: translateX(0);
}


/* lightbox */
.lightbox{
	position: fixed;
	z-index: 1099;
	left:0;
	top:0;
	right:0;
	bottom:0;
	background-color: rgba(0,0,0,0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: zoom-out;
	display: none;
}
.lightbox.open{
	display: flex;
}

.lightbox .lightbox-img{
	display: flex;
	width: auto;
	height: auto;
	max-width: 100%;
	padding:40px 0;
}
.lightbox .img-box{
	position: relative;
	cursor: auto;
}
.lightbox .lightbox-close{
	height: 30px;
	width: 30px;
	display: block;
	position: absolute;
	right: 0;
	top:10px;
	font-size: 30px;
	color:#ffffff;
	text-align: center;
	line-height: 26px;
	cursor: zoom-out;
}
.lightbox .lightbox-caption{
	color:#ffffff;
	font-size: 16px;
	font-weight: 600;
	position: absolute;
	left:0;
	bottom: 10px;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.lightbox .lightbox-caption .lightbox-category{
	padding-right: 50px;
}
.lightbox .lightbox-controls .prev,
.lightbox .lightbox-controls .next{
	position: absolute;
	height: calc(100% - 80px);
	top:40px;
	cursor: pointer;
	display: flex;
	align-items: center;
}
.lightbox .lightbox-controls .prev{
	left:0;
	width: 50%;
	justify-content: flex-start;
	padding-left: 5px;
}
.lightbox .lightbox-controls .next{
	right:0;
	width: 50%;
	justify-content: flex-end;
	padding-right: 5px;
}

.lightbox .lightbox-controls .prev img,
.lightbox .lightbox-controls .next img{
  width: 30px;
  height: 30px;
  padding:5px;
  border-radius: 2px;
  transition: all 0.5s;
}
.lightbox .lightbox-controls .prev:hover img,
.lightbox .lightbox-controls .next:hover img{
background-color: rgba(0,0,0,0.5);
}

.top-bar{
    background-color: #ffffff;
    height: 49px;
    padding: 5px 1px;
}

.top-text{
    color:#000;
}
.top-text span{
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.info-text{
    padding: 7px;
    color: #fff;
}
.social-icon{
    padding-top: 7px;
    padding-right: 25px;
    background-image: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size:20px;
    font-weight:bold;
}
.top-social li{
    padding-top: 7px;
}




/* Booking Page */

.datepickerdiv {
    margin: auto;
    padding: 5px;
    text-align: center;
    border-radius: 10px;
}
/*.datepicker {
    margin: auto;
    padding: 5px;
    text-align: center;
    border-radius: 10px;
}*/
  input[type="date"]{
    padding: 15px;
    width: 250px;
    height: 40px;
    color: #000;
    font-size: 18px;
    outline: none;
    border-radius: 10px;
}

.date{
    margin:auto;
    padding:5px;
    text-align: center;
}
.wrapper{
    margin-top:80px;
    /*margin-bottom:80px;*/
    background-color:#f9fafa;
    padding:30px;
    border-left: 5px solid #A18CD1;
    border-radius:20px;

}



 .label-text{
    background-color: #fff;
    margin-top: -17px;
    position: absolute;
    z-index: 9;
    margin-left: -114px;
    padding: 2px;
}
 .select{
    width: 250px;
    height: 40px;
 }


 .rate-item{
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    padding: 35px 20px;
    border-left: 5px solid #A18CD1;
    border-right: 5px solid #A18CD1;

    transition: all .2s ease-in-out;
    text-align: center;
    position: relative;
    z-index: 1;
    border-radius: 0px 150px 150px 5px;
}


.rate-item:hover {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
    transition:all .2s ease-in-out
}
.rate-item:hover h1,.rate-item:hover .rate-icon,.rate-item:hover .overlay-icon,.rate-item:hover p,.rate-item:hover .read-more {
    color:black;
    transition:all .2s ease-in-out;

}

.img-service{
    border: 2px solid #A18CD1;
    border-radius: 50%;
    padding: 12px;
    background: #A18CD1;
    width:200px;
    height:200px;
}






/* CAKES PAGE */
.switch {
    font-size: 1rem;
    position: relative;
  }
  .switch input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;
  }
  .switch input + label {
    position: relative;
    min-width: calc(calc(2.375rem * .8) * 2);
    border-radius: calc(2.375rem * .8);
    height: calc(2.375rem * .8);
    line-height: calc(2.375rem * .8);
    display: inline-block;
    cursor: pointer;
    outline: none;
    user-select: none;
    vertical-align: middle;
    text-indent: calc(calc(calc(2.375rem * .8) * 2) + .5rem);
  }
  .switch input + label::before,
  .switch input + label::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(calc(2.375rem * .8) * 2);
    bottom: 0;
    display: block;
  }
  .switch input + label::before {
    right: 0;
    background-color: #dee2e6;
    border-radius: calc(2.375rem * .8);
    transition: 0.2s all;
  }
  .switch input + label::after {
    top: 2px;
    left: 2px;
    width: calc(calc(2.375rem * .8) - calc(2px * 2));
    height: calc(calc(2.375rem * .8) - calc(2px * 2));
    border-radius: 50%;
    background-color: white;
    transition: 0.2s all;
  }
  .switch input:checked + label::before {
    background: linear-gradient(90.03deg, #A18CD1 12.95%, #FBC2EB 89.72%);
  }
  .switch input:checked + label::after {
    margin-left: calc(2.375rem * .8);
  }
  .switch input:focus + label::before {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 136, 221, 0.25);
  }
  .switch input:disabled + label {
    color: #868e96;
    cursor: not-allowed;
  }
  .switch input:disabled + label::before {
    background-color: #e9ecef;
  }
  .switch.switch-sm {
    font-size: 0.875rem;
  }
  .switch.switch-sm input + label {
    min-width: calc(calc(1.9375rem * .8) * 2);
    height: calc(1.9375rem * .8);
    line-height: calc(1.9375rem * .8);
    text-indent: calc(calc(calc(1.9375rem * .8) * 2) + .5rem);
  }
  .switch.switch-sm input + label::before {
    width: calc(calc(1.9375rem * .8) * 2);
  }
  .switch.switch-sm input + label::after {
    width: calc(calc(1.9375rem * .8) - calc(2px * 2));
    height: calc(calc(1.9375rem * .8) - calc(2px * 2));
  }
  .switch.switch-sm input:checked + label::after {
    margin-left: calc(1.9375rem * .8);
  }
  .switch.switch-lg {
    font-size: 1.25rem;
  }
  .switch.switch-lg input + label {
    min-width: calc(calc(3rem * .8) * 2);
    height: calc(3rem * .8);
    line-height: calc(3rem * .8);
    text-indent: calc(calc(calc(3rem * .8) * 2) + .5rem);
  }
  .switch.switch-lg input + label::before {
    width: calc(calc(3rem * .8) * 2);
  }
  .switch.switch-lg input + label::after {
    width: calc(calc(3rem * .8) - calc(2px * 2));
    height: calc(calc(3rem * .8) - calc(2px * 2));
  }
  .switch.switch-lg input:checked + label::after {
    margin-left: calc(3rem * .8);
  }
  .switch + .switch {
    margin-left: 1rem;
  }

  .contentA {
    display: none;
  }

  .lab-txt{
    font-size: 30px;
    line-height: 42px;
    color: #263a4f;
    margin: 0 0 10px;
    font-weight: 600;
    padding: 2px;
    letter-spacing: -1px;
  }

  .check{
    margin: 3px;
  }

  .icon-bar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    z-index: 99999;
}

.icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    border-radius: 30px 0px 0px 3px;
    font-size: 20px;
}

.icon-bar a:hover {
    background-color: #000;
}

.facebook {
    background: #1877f2;
    color: white;
}

.whatsapp {
    background: #25d366;
    color: white;
}

.twitter {
    background: #111d79;
    color: white;
}

.google {
    background: #dd4b39;
    color: white;
}

.linkedin {
    background: #007bb5;
    color: white;
}

.youtube {
    background: #bb0000;
    color: white;
}

.content {
    margin-left: 75px;
    font-size: 30px;
}
.selec{
    color:#000;
}
.btn-primary {
    color: #000;
    background-color: #ffffff;
    border-color: #ffffff;
}


.dec-form{
    padding: 25px;
    margin-bottom: 18px;
    border-radius: 13px;
}

.about-content p, .content-info p, .content-info ul li, .about-content ul li{
    color:#fff;
}